import svgIcons from "../Imagens/svg";
import "../Styles/companies.css";
import CompaniesCards from "../utils/CompaniesCards";

export default function Companies() {
  return (
    <div id="companies-container">
      <div id="companies-title">
        <h1>Nossos clientes</h1>
      </div>
      <div id="companies-cards">
        <CompaniesCards imageUrl={svgIcons.sbGoupLogo} text={"SB Group"} />
        <CompaniesCards imageUrl={svgIcons.sbLabsLogo} text={"SB Labs"} />
        <CompaniesCards imageUrl={svgIcons.freeBankLogo} text={"FreeBrank"} />
        <CompaniesCards imageUrl={svgIcons.unimedLogo} text={"Unimed"} />
        <CompaniesCards imageUrl={svgIcons.urbaniiLogo} text={"Urbani"} />
        <CompaniesCards imageUrl={svgIcons.proziLogo} text={"Prozi"} />
      </div>
    </div>
  );
}
