import { useState } from "react";
import "../Styles/solutions.css";
import { LeftArrow, RightArrow } from "../utils/Arrows";

const solutionsData = [
  {
    title: "Unimos a expertise financeira à tecnologia",
    content:
      "Na Premye somos especialistas desenvolvimento e tecnologia, voltado para mercado financeiro. Unimos esses dois mundos para potencializar os resultados da sua empresa.",
  },
  {
    title: "HAHAHAHA 2",
    content:
      "Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    title: "HAHAHAHA 3",
    content:
      "Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

export default function Solutions() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % solutionsData.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? solutionsData.length - 1 : prevIndex - 1
    );
  };

  const step = 220 / (solutionsData.length - 1);

  const currentItem = solutionsData[currentIndex];

  return (
    <div className="solutions-container" id="solutions">
      <div id="solutions-carousel">
        <span id="solutions-carousel-title">Nossas soluções</span>
        <span id="solutions-carousel-subtitle">{currentItem.title}</span>
        <span id="solutions-carousel-content">{currentItem.content}</span>
        <div id="solutions-scrollbar-container">
          <div id="solutions-carousel-scrollbar">
            <div
              id="solutions-scrollbar-picker"
              style={{
                transform: `translateX(${currentIndex * step}px)`,
              }}
            ></div>
          </div>
          <div id="arrows-container">
            <div
              className="arrow-scrollbar"
              id="left-arrow"
              onClick={handlePrev}
            >
              {<LeftArrow />}
            </div>
            <div
              className="arrow-scrollbar"
              id="right-arrow"
              onClick={handleNext}
            >
              {<RightArrow />}
            </div>
          </div>
        </div>
      </div>
      <div id="solutions-image"></div>
    </div>
  );
}
