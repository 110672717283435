import "../Styles/about.css";

export default function About() {
  return (
    <div className="about-container" id="about">
      <div className="about-image"></div>
      <div id="about-text">
        <h1>Sobre a Premye</h1>
        <span>
          Somos uma empresa especializada em soluções estratégicas de tecnologia
          e financeira de alta complexidade. Nosso time é formado por
          especialistas na área de desenvolvimento, tecnologia e financeira.
          Estamso comprometidos em garantir que os seus projetos ocorram com a
          máxima eficiência, confidencialidade e segurança.
        </span>
      </div>
    </div>
  );
}
