import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import "../Styles/header.css";
import HeaderBallon from "../utils/HeaderBallon";

export default function Header() {
  const [isScrolled, setIsScrolled] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsScrolled("isScrolled");
      } else {
        setIsScrolled("");
      }
    });
  }, []);

  return (
    <div className="header-container" id={"header"}>
      <div id="mask" />
      <div className="header_container">
        <header className={`${isScrolled}`}>
          <Link to={"header"} smooth={true}>
            <div id="header-logo"></div>
          </Link>
          <div id="span-header">
            <Link to={"about"} smooth={true}>
              <span>Sobre</span>
            </Link>
            <Link to={"solutions"} smooth={true} offset={-300}>
              <span>Nossas Soluções</span>
            </Link>
            <Link to={"contact-container"} smooth={true} offset={-150}>
              <span>Fale com um especialista</span>
            </Link>
          </div>
        </header>
        <div>
          <span id="content-header">
            Somos digitais antenados & disciplinados.
          </span>
        </div>
      </div>
      <div id="ballon-header">
        <HeaderBallon />
        <i className="bx bx-chat"></i>
      </div>
    </div>
  );
}
