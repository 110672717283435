import React from "react";
import { CardsProps } from "../Interfaces";
import "../Styles/benefits_cards.css";

const BenefitsCards: React.FC<CardsProps> = (props) => {
  const { text, imageUrl } = props;
  return (
    <div id="card-benefits">
      <img id="card-image-benefits" src={imageUrl} alt={text} />
      <span id="card-benefits-text">{text}</span>
    </div>
  );
};

export default BenefitsCards;
