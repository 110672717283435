import React from "react";

const HeaderBallon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="57"
      viewBox="0 0 60 57"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.2039 37.8884C55.3648 34.8137 56 31.481 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56C33.4206 56 38.4815 54.4597 42.7687 51.7928L59.1947 56.0924L54.2039 37.8884Z"
        fill="#0EFFC5"
      />
    </svg>
  );
};

export default HeaderBallon;
