import svgIcons from "../Imagens/svg";
import "../Styles/benefits.css";
import BenefitsCards from "../utils/BenefitsCards";

export default function Benefits() {
  const benefitData = [
    { text: "Princípios acima de tudo", imageUrl: svgIcons.star },
    { text: "Ser mais para o cliente", imageUrl: svgIcons.like },
    { text: "Evoluir e inovar para perenizar", imageUrl: svgIcons.sun },
    { text: "Gente que faz a diferença", imageUrl: svgIcons.users },
    { text: "Agir como dono", imageUrl: svgIcons.smile },
    { text: "Resultados sustentáveis", imageUrl: svgIcons.refresh },
  ];

  return (
    <div className="benefits-container">
      <h1 id="benefits-title">Conheça nossas vantagens</h1>
      <div className="cards-benefits">
        {benefitData.map((benefit, index) => (
          <BenefitsCards
            key={index}
            text={benefit.text}
            imageUrl={benefit.imageUrl}
          />
        ))}
      </div>
    </div>
  );
}
