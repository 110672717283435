import "../Styles/contact.css";

export default function Contact() {
  return (
    <div id="contact-container">
      <div id="mask-contact"></div>
      <div id="contact-content">
        <div id="contact-left">
          <div id="span-contact">
            <span id="contact-title">
              Com grandes projetos construímos grandes propósitos
            </span>
            <span id="contact-subtitle">
              Quer saber mais sobre nossos serviços e consultorias? Entre em
              contato conoso.
            </span>
          </div>
          <a
            href="google.com"
            id="contact-button"
            onClick={() =>
              window.open("https://wa.me/5547991659026", "__blank")
            }
          >
            FALE COM UM ESPECIALISTA
          </a>
        </div>
        <div id="contact-right"></div>
      </div>
    </div>
  );
}
